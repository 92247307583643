import * as React from "react";
import { graphql } from "gatsby";
import Layout from "../components/layout/Layout";
import BlogPostDetailsTemplate from "../components/BlogPostDetailsTemplate";
import { HTMLContent } from "../components/Content";

// markup
const IndexPage = (props) => {
  const data = props.data;
  const jsonData = data.articles.edges[0].node.articles;
  // const langKey = data.markdownRemark.frontmatter.lang;
  const languages = data.site.siteMetadata.languages;
  const id = data.markdownRemark.frontmatter.id;
  const location = props.location;
  const description = data.markdownRemark.frontmatter.description;
  const title = data.markdownRemark.frontmatter.title;
  const date = data.markdownRemark.frontmatter.date;
  const lang = data.markdownRemark.frontmatter.lang;
  const html = data.markdownRemark.html;
  const image = data.markdownRemark.frontmatter.image;
  const imageStatus = data.markdownRemark.frontmatter.imageStatus;
  const tags = data.markdownRemark.frontmatter.tags;

  return (
    <Layout
      title={title}
      jsonData={jsonData}
      languages={languages}
      location={location}
      id={id}
      data={data}
      description={description}
    >
      {date}
      <BlogPostDetailsTemplate
        contentComponent={HTMLContent}
        title={title}
        image={image}
        imageStatus={imageStatus}
        content={html}
        lang={lang}
        tags={tags}
      />
    </Layout>
  );
};

export default IndexPage;

export const pageQuery = graphql`
  query BlogBodyQuery($id: String!) {
    site {
      siteMetadata {
        title
        siteUrl
        description
        languages {
          defaultLangKey
          langs
        }
      }
    }
    articles: allArticlesJson(filter: { title: { eq: "home" } }) {
      edges {
        node {
          articles {
            en
            zh
          }
        }
      }
    }
    markdownRemark(id: { eq: $id }) {
      frontmatter {
        date
        description
        headerDescription
        headers
        id
        slug
        sections
        lang
        tags
        template
        title
        imageStatus
        image {
          childImageSharp {
            gatsbyImageData(
              width: 535
              placeholder: BLURRED
              layout: CONSTRAINED
            )
          }
        }
      }
      html
    }
  }
`;
